import type { Locale } from "@10x/cms/types";
import { languages } from "@10x/site/utils/available-locales";
import { languageToCountryMap } from "@10x/site/utils/available-locales";
import type { GetFooterQuery } from "@10x/types/__generated__/graphql-types";
import {
  colorSteelDarker,
  colorSteelDarkest,
  layoutWidth1200,
  sizeXlarge,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import ISO6391 from "iso-639-1";
import { useRouter } from "next/router";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Branding from "../../Branding";
import Flex from "../../Flex";
import Grid from "../../Grid";
import Text from "../../Text";
import ContactLinks from "./ContactLinks";
import IconLinks from "./IconLinks";
import Links from "./Links";
import SubscribeForm from "./SubscribeForm";

interface Props {
  data: GetFooterQuery;
}

/**
 * Universal footer for all pages without infinite scroll.
 */
const Footer: FunctionComponent<Props> = ({ data }) => {
  const router = useRouter() || { asPath: "" };

  if (!data) {
    return null;
  }

  const { form, links } = data;
  const [formFields] = form?.steps || [];
  const { qrCode } = links || {};

  if (!links?.items) {
    return null;
  }

  const [about, resources, chromium, visium, xenium, legal, contact, social] =
    links.items;

  const handleLocaleChange = (country: string) => {
    // https://nextjs.org/docs/advanced-features/i18n-routing#leveraging-the-next_locale-cookie
    // Set the selected language on the cookie so that the user is returned to it next time:
    document.cookie = `NEXT_LOCALE=${country}`;
  };

  return (
    <footer
      className={"Footer"}
      css={css`
        align-items: center;
        background-color: ${colorSteelDarkest};
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        min-height: 480px;
        padding: ${sizeXlarge} 1rem 0;
        width: 100%;
      `}
    >
      <div
        css={css`
          max-width: ${layoutWidth1200};
          width: 100%;
        `}
      >
        <Grid
          css={css`
            border-bottom: 1px solid ${colorSteelDarker};
            padding-bottom: 2.5rem;
          `}
          gap={"72px"}
          gridTemplateColumns={{
            base: "1fr",
            tabletPortrait: "1fr 1fr",
            tabletLandscape: "repeat(3, auto) 32%",
          }}
        >
          <Flex flexDirection={"column"} gap={"2.5rem"}>
            {about && <Links data={about} />}
            {resources && <Links data={resources} />}
          </Flex>
          {chromium && <Links data={chromium} />}
          <Flex flexDirection={"column"} gap={"2.5rem"}>
            {visium && <Links data={visium} />}
            {xenium && <Links data={xenium} />}
            {legal && <Links data={legal} />}
          </Flex>
          <Flex flexDirection={"column"}>
            {contact && <ContactLinks data={contact} />}
            {formFields.title && (
              <Text
                as={"span"}
                color={"white"}
                css={css`
                  margin: 1.5rem 0 1rem;
                `}
                size={"xsmall"}
                weight={"semibold"}
              >
                {formFields.title}
              </Text>
            )}
            {form && (
              <div
                css={css`
                  padding-bottom: 1.5rem;
                  border-bottom: 1px solid ${colorSteelDarker};
                `}
              >
                <SubscribeForm data={form} subscribeUrl={"/subscribe"} />
              </div>
            )}
            {social && <IconLinks data={social} />}
            <Grid
              css={css`
                padding-top: 1.5rem;
              `}
              gap={"1rem"}
            >
              <Branding />
              <Flex gap={"1rem"}>
                {languages.map((language) => {
                  const nativeName = ISO6391.getNativeName(language);
                  const country = languageToCountryMap[language] as Locale;

                  return (
                    <Text
                      as={"span"}
                      color={"white"}
                      key={country}
                      size={"xsmall"}
                    >
                      <Anchor
                        color={"inherit"}
                        // Server doesn't get hash params, so it makes an error
                        // when the client does see the hash params
                        href={router.asPath.split("#")[0]}
                        locale={country}
                        onClick={() => handleLocaleChange(country)}
                        underlineOnHover={true}
                      >
                        {nativeName}
                      </Anchor>
                    </Text>
                  );
                })}
              </Flex>
              <Anchor className={"cky-banner-element"} href={"#"}>
                <Text as={"span"} color={"white"} size={"xsmall"}>
                  {"Manage Cookie Preferences"}
                </Text>
              </Anchor>
            </Grid>
            {qrCode?.image ? (
              <img
                alt={qrCode.label || undefined}
                css={css`
                  max-height: 150px;
                  max-width: 150px;
                  padding-top: 2.5rem;
                `}
                src={qrCode.image}
              />
            ) : null}
          </Flex>
        </Grid>
        <Text
          as={"div"}
          color={"white"}
          css={css`
            padding: 1.5rem 0;
          `}
          size={"xsmall"}
        >
          {`© ${new Date().getFullYear()} 10x Genomics. All Rights Reserved.`}
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
